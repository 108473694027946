import './skills.scss'

const skills = () => {
  return (
    <section className="container">
      <div className="skills-wrapper">
        <h2 className="skills-container">My Skills</h2>
        <div className="skills-list slide-in">
          <div className="skill-item">Problem Solving</div>
          <div className="skill-item">SEO</div>
          <div className="skill-item">Customer Engagement</div>
          <div className="skill-item">Error collection</div>
          <div className="skill-item">Performance Optimization</div>
          <div className="skill-item">Data led updates</div>
          <div className="skill-item">Data measured improvements</div>
        </div>
      </div>
    </section>
  )
}

export default skills
