const getStatusColor = (status) => {
  let statusColor = ''
  switch (status) {
    case 'Not Started':
      statusColor = 'white'
      break
    case 'Pending':
      statusColor = 'yellow'
      break
    case 'Fulfilled':
      statusColor = 'green'
      break
    case 'Rejected':
      statusColor = 'red'
      break
    default:
      statusColor = 'white'
  }

  return statusColor
}

export default getStatusColor
