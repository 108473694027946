import React from 'react'
import './footer.scss'
const Footer = () => {
  return (
    <footer className="footer">
      <p className="fade-in">
        © {new Date().getFullYear()} Kristian. Built with ❤️ and React and many
        arguments with Copilot.
      </p>
    </footer>
  )
}

export default Footer
