import React, { useState, useRef, useEffect } from 'react'
import './aboutSection.scss'

const clickToRestart = 'Click anywhere in this window to restart animations'
const clickToStop = 'Click anywhere in this window to stop animations'

const AboutSection = () => {
  const [animationsActive, setAnimationsActive] = useState(true) // Track if animations are active
  const [text, setText] = useState(clickToStop) // Dynamic button text
  const animationsCountRef = useRef(0) // Ref to track the number of active animations

  const handleToggleAnimations = () => {
    if (animationsActive) {
      setAnimationsActive(false) // Stop animations
      setText(clickToRestart)
    } else {
      setAnimationsActive(true) // Restart animations
      setText(clickToStop)
    }
  }

  const handleAnimationStart = () => {
    animationsCountRef.current += 1 // Increment the animation count
  }

  const handleAnimationEnd = () => {
    animationsCountRef.current -= 1 // Decrement the animation count

    if (animationsCountRef.current <= 0) {
      // If no animations are running, update state
      animationsCountRef.current = 0 // Ensure it doesn't go negative
      setAnimationsActive(false)
      setText(clickToRestart)
    }
  }

  useEffect(() => {
    // Reset count if animationsActive is toggled
    if (!animationsActive) {
      animationsCountRef.current = 0
    }
  }, [animationsActive])

  const listItems = [
    'Fixing Latency issues in live chatbot calls using cloud functions',
    'Optimise React application performance',
    'Custom API creation',
    'Implemented production system live error reporting',
    'Utilising GitHub Actions to schedule updates using internal API keys',
    'Updating JavaScript repos to TypeScript whilst still continuing to implement features',
    'Adding new tests and refactoring code',
    'Updating codebases to use the latest packages where necessary for security updates or tied peer dependencies',
    'Develop a small React app that helps users annotate text.',
    'Develop an educational activity that split words into syllables and highlighted each word to achieve a set reading speed. It accounted for the difficulty of words (syllable count) over the entire text and allowed users to start, stop, pause, or select a specific word by tapping.',
    'Implemented a software switched failover system so two systems can use one service.',
  ]

  const totalGroupDuration = 1 // Total animation duration for each li

  const createListFromItems = (items) => {
    return items.map((item, listIndex) => {
      const groupOffset = listIndex * 0.5 // Stagger list items by 0.5s
      const totalLetters = item.replace(/ /g, '').length // Count only letters, ignore spaces
      const letterDuration = totalGroupDuration / totalLetters // Each letter's animation duration

      let globalCharIndex = 0 // To calculate delays across the entire string

      // Split the sentence into words
      const words = item.split(' ')

      return (
        <li
          key={listIndex}
          className="list-item"
          style={{ animationDelay: `${groupOffset}s` }}
        >
          {words.map((word, wordIndex) => (
            <span key={wordIndex} className="no-break">
              {word.split('').map((char, charIndex) => {
                const letterOffset = globalCharIndex * letterDuration // Offset for each letter
                const totalOffset = groupOffset + letterOffset // Combine group and letter offsets
                globalCharIndex++ // Increment the global character index

                return (
                  <span
                    key={charIndex}
                    className="letter"
                    style={{
                      animationDelay: `${totalOffset}s`,
                      animationDuration: `${letterDuration}s`,
                    }}
                  >
                    {char}
                  </span>
                )
              })}
              {wordIndex < words.length - 1 && (
                <span className="space">{'\u00A0'}</span> // Add space between words
              )}
            </span>
          ))}
        </li>
      )
    })
  }

  return (
    <section
      className={`about ${animationsActive ? '' : 'animations-stopped'}`} // Toggle class
      onClick={handleToggleAnimations} // Click to toggle animations
    >
      <div className="container">
        <h2 className="fade-in">About Me</h2>
        <p className="fade-in delay-1">
          I have helped supply solutions to many problems:
        </p>
        <ul
          className="staggered-list"
          onAnimationStart={handleAnimationStart}
          onAnimationEnd={handleAnimationEnd}
        >
          {createListFromItems(listItems)}
        </ul>
        <p className="animation-status">{text}</p>
      </div>
    </section>
  )
}

export default AboutSection
