import React, { useRef, useState } from 'react'
import '../async-group.scss'
import AnimItem from '../async-item'
import getStatusColor from '../getStatusColor'

export const AsyncPromiseOneFailGroup = () => {
  const [firstAnimsResult, setFirstAnimsResult] = useState('Not Started')

  const [anim1Status, setAnim1Status] = useState('Not Started')
  const [anim2Status, setAnim2Status] = useState('Not Started')
  const [anim3Status, setAnim3Status] = useState('Not Started')

  const anim1 = useRef()
  const anim2 = useRef()
  const anim3 = useRef()

  const resetPromisesAllAnim = () => {
    setFirstAnimsResult('Not Started')
    setAnim1Status('Not Started')
    setAnim2Status('Not Started')
    setAnim3Status('Not Started')
    anim1.current.classList.remove('ani-async-1s')
    anim2.current.classList.remove('ani-async-3s')
    anim3.current.classList.remove('ani-async-2s')
  }

  const startPromiseAllAnim = async () => {
    setFirstAnimsResult('Pending')
    setAnim1Status('Pending')
    setAnim2Status('Pending')
    setAnim3Status('Pending')

    anim1.current.classList.add('ani-async-1s')
    anim2.current.classList.add('ani-async-3s')
    anim3.current.classList.add('ani-async-2s')

    // anim2.getAnimations().map(
    //   (animation) =>
    //     (animation.oncancel = () => {
    //       Promise.reject(new Error('Animation cancelled'))
    //     })
    // )

    anim1.current.onanimationend = () => {
      setAnim1Status('Fulfilled')
      anim2.current.getAnimations().map((animation) => {
        animation.cancel()
        setAnim2Status('Rejected')
        setFirstAnimsResult('Rejected')
      })
    }

    anim2.current.onanimationend = () => {
      setAnim2Status('Fulfilled')
    }

    anim3.current.onanimationend = () => {
      setAnim3Status('Fulfilled')
    }

    await Promise.all([
      ...(anim1.current && anim1.current.getAnimations()
        ? anim1.current.getAnimations().map((animation) => animation.finished)
        : []),
      ...(anim2.current && anim2.current.getAnimations()
        ? anim2.current.getAnimations().map((animation) => animation.finished)
        : []),
      ...(anim3.current && anim3.current.getAnimations()
        ? anim3.current.getAnimations().map((animation) => animation.finished)
        : []),
    ])
  }

  return (
    <React.Fragment>
      <h3>Promise.all - One Fails</h3>
      <p>Wait 1 to fail</p>
      {/* // Should this fail as soon as one fails */}
      <p>
        With Promise.all, every promise in the input array must be successfully
        fulfilled for the overall Promise.all to be considered successful.
        However, if any of the promises fail, Promise.all immediately fails,
        regardless of the status of the remaining promises.
      </p>
      <button
        className="animation-ui-button"
        onClick={
          firstAnimsResult === 'Not Started'
            ? startPromiseAllAnim
            : resetPromisesAllAnim
        }
      >
        {firstAnimsResult === 'Not Started' ? 'Start' : 'Reset'}
      </button>
      {/* <button onClick={startPromiseAllAnim}>Start Animations</button> */}
      <div className="example">
        <div className="test">
          <AnimItem
            backgroundColor={getStatusColor(anim1Status)}
            ref={anim1}
            status={anim1Status}
          />
          <AnimItem
            backgroundColor={getStatusColor(anim2Status)}
            ref={anim2}
            status={anim2Status}
          />
          <AnimItem
            backgroundColor={getStatusColor(anim3Status)}
            ref={anim3}
            status={anim3Status}
          />
        </div>
        <div
          style={{ backgroundColor: getStatusColor(firstAnimsResult) }}
          className="result"
        >
          <div className="result-text">
            <span>Promise.all Result:</span>
            <span>{firstAnimsResult}</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AsyncPromiseOneFailGroup
