import Navbar from '../navbar'
import React from 'react'
import './app-style.scss'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from '../home'
import CVCompleteDB from '../cvDB'
import BlogList from '../blogs'
import blogRoutes from '../blogs/blogRoutes'
import CVComplete from '../cv-complete'
import Footer from '../footer'

class App extends React.Component {
  render() {
    return (
      <Router>
        <Navbar />
        <div className="main">
          <Routes>
            {/* Home Route */}
            <Route path="/" element={<Home />} />

            {/* CV Complete Route */}
            <Route path="/cv" element={<CVComplete />} />

            {/* Blog List Route */}
            <Route path="/blogs" element={<BlogList />} />

            {/* Dynamic Blog Routes */}
            {Object.entries(blogRoutes).map(([slug, Component]) => (
              <Route
                key={slug}
                path={`/blogs/${slug}`}
                element={<Component />} // Proper usage of the element prop
              />
            ))}

            {/* CVCompleteDB Routes */}
            <Route path="/cv-db" element={<CVCompleteDB />} />

            {/* Default Route (optional) */}
            <Route path="*" element={<Home />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    )
  }
}

export default App
