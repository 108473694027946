import React from 'react'
import { NavLink } from 'react-router-dom'

const BlogList = () => {
  const blogs = [{ title: 'First Blog Post', slug: 'blog-support-promises' }]

  return (
    <div>
      <h1>Blog List</h1>
      <ul>
        {blogs.map((blog) => (
          <li key={blog.slug}>
            <NavLink to={`/blogs/${blog.slug}`}>{blog.title}</NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default BlogList
