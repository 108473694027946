import React from 'react'
import './home-page.scss'
import me from './me.jpg'
import AboutSection from './about'
import SkillsSection from './skills'
const HomePage = () => {
  const handleGetInTouhClick = () => {
    alert('Contact me on kristiansigston@gmail.com') // Implement this function
  }

  const handleNothingClick = () => {
    alert(
      "Ok I fibbed a bit. The button displays this specific alert but it's pretty boring"
    ) // Implement this function
  }
  return (
    <div className="homepage">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1 className="fade-in">Hi, I'm Kristian</h1>
          <p className="slide-up">
            A committed full stack software developer specialising in Nodejs,
            React and TypeScript. Conversant with Agile methodologies and
            Versioning control systems (GIT for example).
          </p>
          <div className="cta-buttons fade-in">
            <button onClick={handleNothingClick} className="btn primary">
              This button does nothing
            </button>
            <button onClick={handleGetInTouhClick} className="btn primary">
              Get in Touch
            </button>
          </div>
        </div>
        <div className="hero-image">
          <img src={me} alt="Personal Hero" className="zoom-in" />
        </div>
      </section>
      <div className="mid-sections">
        {/* About Section */}
        <AboutSection />

        <SkillsSection />
      </div>

      {/* Footer Section */}
    </div>
  )
}

export default HomePage
