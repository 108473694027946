import React from 'react'
import './navbar-styles.scss'
import { NavLink } from 'react-router-dom'

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar__logo">KS</div>
      <div className="navbar__items">
        <NavLink
          className={({ isActive }) => (isActive ? 'active' : '')}
          to="/"
        >
          Home
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? 'active' : '')}
          to="/blogs"
        >
          Blogs
        </NavLink>
        <NavLink
          className={({ isActive }) => (isActive ? 'active' : '')}
          to="/cv"
        >
          CV
        </NavLink>
        {/* <NavLink
          
          
          className={({ isActive }) => (isActive ? 'active' : '')}
          to="/cv-print"
        >
          CV-Download
        </NavLink> */}
      </div>
    </nav>
  )
}

export default Navbar
