const Introduction = ({ title, id, smallFont = false }) => {
  const style = smallFont ? {} : { fontSize: '1.5em' }
  return (
    <div className="section" id={id}>
      <h2 className="section__title">{title}</h2>
      <div className="interests"></div>
      <div style={style}>
        <p>Hi! Let's make something awesome together!</p>
        <p>
          I’m a full stack developer, from the UK, traveling around the globe
          working fully remote and really enjoying the opportunity to explore
          all the wonderful cultures.
        </p>
        <p>
          My full tech stack is listed further down, including NodeJS and
          PostgreSQL, but I’m happy developing in any domain and love to learn
          new things. I bring a creative approach to problem-solving, supported
          by measurable data. Where data is lacking, I help identify and gather
          it. I base decisions on solid testing, implemented to ensure we have
          actionable insights. I deliver solid working code, on time, on budget,
          documented, and to spec, solving user problems, driving customer
          sales, customer satisfaction and revenue growth. I am committed to
          supporting my colleagues and peers, sharing my knowledge and expertise
          to help others succeed. My background, shaped by various experiences,
          provides me with unique insights and perspectives that
        </p>
        <p>
          I enjoy sharing knowledge with everyone. In my spare time, I am
          constantly seeking opportunities to learn and grow, including guitar
          and daily 5 km runs, taking training courses and improving my tech
          skills with personal projects. I believe that a well-rounded and
          balanced lifestyle helps me think more clearly and helps me perform at
          my best.
        </p>
        <p>Let’s talk!</p>
        <p>Kristian</p>
      </div>
    </div>
  )
}

export default Introduction
