const style = {
  position: 'fixed',
  top: '5em',
  left: '2em',
  width: '10em',
}

const AboutMe = () => {
  return (
    <div style={style}>
      <h3>Kristian Sigston</h3>
      <p>Fullstack Software Developer and Problem Solver</p>
      <p>----------</p>
      <a href="#">
        <p>Introduction</p>
      </a>
      <a href="#work-experience">
        <p>Work Experience</p>
      </a>
      <a href="#languages-tech">
        <p>Languages and Technology</p>
      </a>
      <a href="#educations">
        <p>Educations</p>
      </a>
      <a href="#courses">
        <p>Courses</p>
      </a>
      <a href="#projects">
        <p>Projects</p>
      </a>
      <a href="#blog-posts">
        <p>Blog Posts</p>
      </a>
      <a href="#interests">
        <p>Interests</p>
      </a>
      <a href="#social">
        <p>Social</p>
      </a>
      <a href="#non-fiction-books">
        <p>Non Fiction Books</p>
      </a>
      <a href="#fiction-books">
        <p>Fiction Books</p>
      </a>
    </div>
  )
}

export default AboutMe
